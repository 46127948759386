import React from "react";

function NavbarContainer(props) {
  const { color, spaced, className, dashboard, ...otherProps } = props;

  return (
    <nav
      className={
        "navbar" +
        (color ? ` is-${color}` : "") +
        (spaced ? " is-spaced" : "") +
        (className ? ` ${className}` : "")
      }
      {...otherProps}
      style={{position: dashboard ? "sticky" : "", top: dashboard ?  "0" : ""}}
    >
      {props.children}
    </nav>
  );
}

export default NavbarContainer;
