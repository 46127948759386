import React from "react";
import { motion } from "framer-motion";

function LoginButton(props) {
  const {
    parentColor,
    size,
    state,
    fullWidth,
    // Passed to button element
    ...otherProps
  } = props;

  return (
    <motion.button
      whileHover={{ scale: 1.055, backgroundColor: "#FFF" }}
      whileTap={{ scale: 0.965, backgroundColor: "#FFF" }}
      style={{
        backgroundColor: "#F4F5F7",
        borderRadius: "8px",
        fontWeight: "700",
        color: "#0C92A0",
        boxShadow:
          "rgba(194, 200, 206, 0.8) 0px 4px 10px -4px, rgba(194, 200, 206, 0.8) 0px 1px 4px 0px, rgba(255, 255, 255, 0.4) 2px 2px 4px 0px inset",
      }}
      className={
        "button" +
        (["primary", "info", "success", "warning", "danger", "black", "dark"].includes(parentColor)
          ? ` is-${parentColor} is-inverted`
          : "") +
        (["white", "light"].includes(parentColor) || !parentColor ? " is-primary" : "") +
        (size ? ` is-${size}` : "") +
        (state ? ` is-${state}` : "") +
        (fullWidth ? " is-fullwidth" : "")
      }
      {...otherProps}
    >
      {props.children}
    </motion.button>
  );
}

export default LoginButton;
