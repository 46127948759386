import React, { useState, useEffect, useLayoutEffect } from 'react';
import NavbarContainer from 'components/NavbarContainer';
import Link from 'next/link';
import Image from 'next/image';
import LoginButton from 'components/LoginButton';
import { signout, useAuth } from 'util/auth';
import { useRouter } from 'next/router';
import {
    motion,
    useViewportScroll,
    useTransform,
    motionValue
} from 'framer-motion';
import useStore from './sharedCode/stores/store';

function Navbar(props) {
    let authUser = useStore((state) => state.authUser);
    const router = useRouter();
    const [menuOpen, setMenuOpen] = useState(false);
    const current = router.pathname;
    const shortPath = current.substring(0, 6);
    const isGuide = shortPath === '/guide';
    const isSharedPage =
        shortPath === '/share' ||
        current === '/extension-installed' ||
        current === '/extension-uninstalled';

    //const dashboardPage = (current === "/dashboard" || isGuide === "/guide/") ? true : false
    const dashboardPage = current === '/dashboard' ? true : false;

    const { scrollYProgress } = useViewportScroll();
    let iconOpacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);
    let iconScale = useTransform(scrollYProgress, [0, 0.04], [0, -30]);

    const contentOffsetY = motionValue(scrollYProgress);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        if (isGuide || dashboardPage) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }, [router]);

    useEffect(() => {
        if (menuOpen === true && dashboardPage) {
            document.body.style.overflow = 'hidden';
            window.scrollTo(0, 0);
        }
        if (menuOpen === false && dashboardPage) {
            document.body.style.overflow = 'unset';
        }
    }, [menuOpen]);

    if (isSharedPage) {
        return null;
    }
    return (
        <NavbarContainer
            spaced={props.spaced}
            color={props.color}
            dashboard={dashboardPage}
        >
            <div className="container">
                <div className={'navbar-brand' + (menuOpen ? ' navTop' : '')}>
                    <motion.div
                        className="navbar-item"
                        style={{
                            opacity: dashboardPage ? iconOpacity : 1,
                            y: dashboardPage ? iconScale : ''
                        }}
                    >
                        <Link href="/">
                            <img
                                src={props.logo}
                                style={{ maxHeight: '2.75rem' }}
                                width="186px"
                                height="44px"
                                alt="tabExtend logo"
                            />
                        </Link>
                    </motion.div>
                    <div
                        className={
                            'navbar-burger burger' +
                            (menuOpen ? ' is-active' : '')
                        }
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div
                    className={
                        'navbar-menu' + (menuOpen ? ' is-active navShadow' : '')
                    }
                >
                    <div className="navbar-end">
                        <Link
                            href="/guide/getting-started"
                            className="navbar-item catFont borderRightNav"
                            style={{ color: '#4A545E' }}
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            Guide
                        </Link>

                        <Link
                            href="/pricing"
                            className="navbar-item catFont borderRightNav"
                            style={{ color: '#4A545E' }}
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            Pricing
                        </Link>

                        <Link
                            href="/updates"
                            className={
                                'navbar-item catFont guideNoBorder' +
                                (authUser ? ' borderRightNav' : '')
                            }
                            style={{ color: '#4A545E' }}
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            Updates
                        </Link>

                        {authUser && (
                            <div className="navbar-item has-dropdown is-hoverable">
                                <div
                                    //href="/dashboard"
                                    className="navbar-link accountHeaderNavItem hoverNav catFont"
                                    style={{ color: '#4A545E' }}
                                    onClick={() => setMenuOpen(!menuOpen)}
                                >
                                    Account
                                </div>

                                <div className="navbar-dropdown">
                                    <Link
                                        href="/dashboard"
                                        className="navbar-item catFont accountNavItem catFont"
                                        onClick={() => setMenuOpen(!menuOpen)}
                                    >
                                        App →
                                    </Link>

                                    <Link
                                        href="/settings/general"
                                        className="navbar-item catFont accountNavItem catFont"
                                        onClick={() => setMenuOpen(!menuOpen)}
                                    >
                                        Settings
                                    </Link>

                                    <hr className="dropdown-divider"></hr>

                                    <Link
                                        href="/auth/signout"
                                        className="navbar-item catFont"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            signout();
                                        }}
                                    >
                                        Sign out
                                    </Link>
                                </div>
                            </div>
                        )}

                        {!authUser && (
                            <>
                                <div className="navbar-item guideNoBorder">
                                    <LoginButton
                                        parentColor={props.color}
                                        size="normal"
                                        onClick={() => {
                                            router.push('/auth/signin');
                                        }}
                                    >
                                        Login
                                    </LoginButton>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </NavbarContainer>
    );
}

export default Navbar;
